@import './_includes/normalize';

/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import './_includes/variables';
@import './_includes/mixins';



p,img,table,td,tr,div,nav,footer,section,article,header,video,input,a,input[type=password],button { background-repeat:no-repeat; }
p,div,nav,footer,section,header,input[type=text],textarea,select,article,img,input[type=password],button { box-sizing: border-box; background-repeat:no-repeat; }

html {
	font-family: $_STDFont;
}
h1,h2,h3,h4,h5,h6,button,a {
	font-family: $_FANCYFONT;
}
/* == OTHER WEBSITE STYLE SHEETS */
